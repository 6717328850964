.copy-container {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  border: none;
}

@media print {
  .copy-container {
    height: 100%;
  }
}

.signature {
  border: #cecece solid 1px;
}
.options {
  p {
    label {
      input {
        margin-right: 10px;
      }
    }
  }
}
@media (max-width: 480px) {
  .option-buttons {
    button {
      width: 100%;
    }
  }
}

@media (min-width: 481px) {
  .option-buttons {
    button {
      margin-right: 10px;
    }
  }
}
.option-buttons {
  button {
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) {
  .go-back-holder {
    p {
      margin: 5px 0;
    }
  }
}