* {
  font-family: "Trebuchet MS", sans-serif;
}
.rbg-app {
  .open-contract {
    .input-group {
      margin-top: 30px;
    }
  }
  .navbar {
    padding: 15px 0 0 0;
    background: #0B163E;

    li, a, span, p, li a {
      color: #FFF;
    }
    .navbar-header {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
      .rbg-logo {
        margin: 10px;
      }
      .brand-logo {
        margin: 10px;
      }
    }
    .navbar-brand {
      margin-top: 0;
      padding-top: 7px;
      width: 100%;
    }

    .navbar-collapse {
      li {
        a {
          color: #FFF;
        }

        &.active {
          a {
            background-color: #FFF;
            color: #333;
          }
        }
      }
    }

    .dropdown {
      .open {
        background-color: #FFF;
      }
    }

    .dropdown-menu {
      background: #E23B2D;

      li {
        &:hover {
          a {
            background: #FFF;
            color: #333;
          }
        }
      }
    }
  }
  .intro-image {
    max-width: 100%;
  }
  .navbar-header {

  }

  .main-content {
    padding-top: 80px;
    padding-bottom:40px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.container-fluid {
  .page-header {
    background: #E23B2D;

    .logo {
      img {
        width: 100px;
      }
    }

    h1 {
      color: #FFF;
      font-size: 18px;
      line-height: 50px;
    }

    h2 {
      color: #FFF;
      font-size: 14px;
      line-height: 40px;
    }
  }
}

textarea {
  min-height: 150px;
}

.rbg-app {
  .modal {
    overflow-y: auto;
    display: block;
    background-color: rgba(51, 51, 51, 0.7);
  }
}

.panel-default {
  .pagination-holder {
    .result-text, .pagination {
      margin: 0;
    }
  }
}

.holder-div {
  min-height: 45px;
}