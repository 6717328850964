.rbg-app {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .boolean-holder {
    label {
      margin-right: 10px;
    }

    input {
      margin-right: 5px;
    }
  }
}